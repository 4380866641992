import React from 'react'

import { ColumnConnector } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="ColumnConnector"
    components={[{ component: ColumnConnector }]}
  >
    <Section>
      <Playground>
        <ColumnConnector />
      </Playground>
    </Section>
  </Content>
)

export default Page
